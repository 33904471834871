@font-face {
    font-family: 'Metro Sans';
    src: url('MetroSans-Regular.eot');
    src: url('MetroSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('MetroSans-Regular.woff2') format('woff2'),
        url('MetroSans-Regular.woff') format('woff'),
        url('MetroSans-Regular.ttf') format('truetype'),
        url('MetroSans-Regular.svg#MetroSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

